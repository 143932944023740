import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { GATSBY_HELP_EMAIL } from "gatsby-env-variables"

import Layout from "layout/Layout"
import Container from "layout/Container"
import { AppContext } from "../context/AppContext"

export default () => {
  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    if (!state?.requestComplete) {
      navigate("/")
    }
  }, [])

  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/contactUs__complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const complete = data.complete.childImageSharp.fluid

  const handleFinish = async () => {
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    await dispatch({ type: "RESET_DETAILS" })
    navigate("/")
  }

  return (
    <Layout seoTitle="Teleconsult Request Sent">
      <Container isCentered>
        <center>
          <h1 className="title is-size-3 mt-5 mb-2">Thank you!</h1>
          <Container isCentered mobile={8} tablet={4} desktop={3}>
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1 mx-3">
            Our mental health coordinator will contact you through SMS or email.
            If you don’t hear from us within the next working day, or should you
            wish to change some of your answers, please contact us at{" "}
            <a
              href={`mailto:${GATSBY_HELP_EMAIL}`}
              className="has-text-weight-bold"
            >
              {GATSBY_HELP_EMAIL}
            </a>
            .
          </p>
          <button
            onClick={handleFinish}
            className="button is-primary is-medium"
          >
            Finish
          </button>
        </center>
      </Container>
    </Layout>
  )
}
